<template>
  <div class="dashboard-content">
    <div class="row">
       <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
           <div class="row">
             <div class="col-md-6">
              <h6 class="mb-2" style="text-transform:uppercase;display:inline !important">
                Checkout
              </h6>
             </div>
             <div class="col-md-6">
               
              <h6 class="font-weight-bold " style="float:right;display:inline !important">  <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
 | {{total_locations}} Item</h6> 
             </div>
           </div>
            <hr>
            <div class="row container">
                <div class="col-md-8 table-responsive">
                <table class="table table-borderless " style="border:none">
                  <tr>
                    <th style="color:#007bff"> ITEMS </th>
                    <th style="color:#007bff"> PRICE </th>
                  </tr>
                  <tr>
                    <td style="color:#4d4d4d" class="font-weight-bold"> <h5>77007({{total_locations}}) ZIP CODE</h5> </td>
                    <td style="color:#4d4d4d"> <h4>${{ price* total_locations }}</h4> </td>
                  </tr>
                </table>
                </div>
            </div>
            <div class="row">
              <div class="col-md-3">
               <h5 style="color:#4d4d4d">Payment Method</h5>
                <img src="/static/images/visa.png"  style="width:120px;height:100px" alt="">
              </div>
              </div>
            <div class="row">
                <div class=col-md-6>
                    <router-link to="/create-child-ad" class="btn btn-success mt-3"><i class="fas fa-arrow-left"></i> Back</router-link>
                </div>
                <div class=col-md-6>
                    <button :disabled="total_locations <= 0" class="btn btn-primary mt-3" style="float:right" @click="placeOrder"> <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>  &nbsp; Place Order  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="copyrights">
          © 2021 Thriggle. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {axios} from '../../../services/http-common';
export default {
  data(){
    return{
      total_locations: '',
      price:5,
    }
  },
  methods:{
 placeOrder(){

    let image = localStorage.getItem('child_ad_image');
    let ad_link = localStorage.getItem('ad_link');
    let ad_link_status = localStorage.getItem('child_ad_link');

    return axios.post(`${process.env.VUE_APP_API_URL}realtor_ad_added`,{image: image, ad_link: ad_link, ad_link_status:ad_link_status  })
    .then(response => {
       this.$toast.success('Ad Created For All Added Locations', {
              position: "bottom-right",
              timeout: 2508,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
              top:'300px',
            });
   
      localStorage.removeItem('child_ad_image');
      localStorage.removeItem('ad_link');
      localStorage.removeItem('child_ad_link');
      this.$router.push({path:'select-ad'});
    }).catch(error => {
      console.log('Error');
    });
 }
  },
 
  mounted(){
    return axios
            .get(`${process.env.VUE_APP_API_URL}total_locations`)
            .then(response => {
              this.total_locations = response.data;
         }).catch(error => {
            this.$toast.error('Something Went Wrong!', {
                  position: "bottom-right",
                  timeout: 2508,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                  top:'300px',
                });
         });
  }
}
</script>
